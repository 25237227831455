import axios from "axios";
import {useEffect} from "react";
import {useSelector} from "react-redux";
import {message} from "antd";

const searchParams = new URLSearchParams(window.location.search);
const token = searchParams.get('token');

const instance = axios.create();
// instance.defaults.baseURL = 'http://microvpp.swdnkj.com/saas-api'; // swdn.eepx.com.cn
instance.defaults.baseURL = '/saas-api';
instance.interceptors.request.use(
    config => {
        config.headers['Authorization'] = `Bearer ${token}`;
        if (localStorage.getItem('tenantId')) {
            config.headers['TenantId'] = localStorage.getItem('tenantId')
        }
        return config;
    },
    error => {
        return Promise.reject(error)
    }
)
instance.interceptors.response.use(
    response => {
        return response
    },
    error => {
        if (error.response.status === 401) {
            // window.location.href = 'http://microvpp.swdnkj.com/sso/logout?redirect=http://localhost:7700';
            window.location.href = `${window.location.protocol}//${window.location.host}/sso/logout?redirect=${window.location.protocol}//${window.location.host}`;
        }
        return Promise.reject(error)
    }
)

export default {
    metaSources: [],
    GetUserInfo(params) {
        return instance.get('/authority/oauth/userInfo', { params })
    },
    ChangePwd(params) {
        return instance.put('/authority/oauth/change_password', params)
    },
    GetUserDevices() {
        return instance.get('/authority/oauth/getUserDevices')
    },
    GetVppList () {
        return instance.get('/vpp/power-plant/all')
    }
}