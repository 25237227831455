const map = {
    "//localhost:8600/": "saas/",
    "//localhost:8602/": "wdw/",
    "//localhost:7000/": "wdw/eneoverview", // 能耗管理--概况
    "//localhost:7001/": "wdw/enebranch", // 能耗管理--支路能耗统计
    "//localhost:7002/": "wdw/enegroup", // 能耗管理--分组能耗统计
    "//localhost:7003/": "wdw/enebackstep", // 能耗管理--能耗折标分析
    "//localhost:7004/": "wdw/eneyoy", // 能耗管理--能耗同比
    "//localhost:7005/": "wdw/eneqoq", // 能耗管理--能耗环比
    "//localhost:7006/": "wdw/eneflow", // 能耗管理--能耗流向图
    "//localhost:7007/": "wdw/enesetting", // 能耗管理--分类分项配置
    "//localhost:7100/": "wdw/optoverview", // 运维管理--概况
    "//localhost:7101/": "wdw/opttour", // 运维管理--巡视工单
    "//localhost:7102/": "wdw/optrepair", // 运维管理--抢修工单
    "//localhost:7103/": "wdw/optdefect", // 运维管理--缺陷工单
    "//localhost:7104/": "wdw/optmaintenance", // 运维管理--维保工单
    "//localhost:7105/": "wdw/optproject", // 运维管理--项目配置
    "//localhost:7106/": "wdw/opttemplate", // 运维管理--模板配置
    "//localhost:7107/": "wdw/optplan", // 运维管理--巡视计划
    "//localhost:7108/": "wdw/optgroup", // 运维管理--运维班组
    "//localhost:8601/": "wdw/overView", // 驾驶舱--微网概况
    "//localhost:8603/": "wdw/screen", // 驾驶舱--光储充大屏
    "//localhost:8626/": "wdw/wideScreen", // 驾驶舱--光储充大屏20:9
    "//localhost:8625/": "wdw/simple", // 驾驶舱--简版大屏
    "//localhost:8604/": "wdw/electricityOverview",  // 配电监测--运行概况
    "//localhost:8605/": "wdw/powercspt", // 配电监测--用电量统计
    "//localhost:8606/": "wdw/powermgnt", // 配电监测--支路用电统计
    "//localhost:8607/": "wdw/transformer", // 配电监测--时段用电监测
    "//localhost:8608/": "wdw/trend", // 配电监测--时段用电对比
    "//localhost:8609/": "wdw/device", // 配电监测--设备用电对比
    "//localhost:8700/": "wdw/powerqult", // 配电监测--电能质量监测
    "//localhost:8701/": "wdw/enviroment", // 配电监测--环境监测
    "//localhost:8702/": "wdw/compyoy", // 配电监测--同环比分析
    "//localhost:8703/": "wdw/reportdoc", // 配电监测--用电分析报告
    "//localhost:8704/": "wdw/reportfgp", // 配电监测--尖峰平谷报表
    "//localhost:8705/": "wdw/reportmeter", // 配电监测--远程抄表
    "//localhost:8706/": "wdw/reportload", // 配电监测--负荷极值报表
    "//localhost:8707/": "wdw/feecos", // 配电监测--力调电费分析
    "//localhost:8708/": "wdw/traiff", // 配电监测--基本电费分析
    "//localhost:8709/": "wdw/transfanaly", // 配电监测--变压器经济运行
    "//localhost:8610/": "wdw/pvOverview", // 配电监测--运行概况
    "//localhost:8611/": "wdw/powerMonitor", // 配电监测--发用电量统计
    "//localhost:8612/": "wdw/inverterMonitor", // 配电监测--逆变器监测
    "//localhost:8613/": "wdw/contrastAnalysis", // 配电监测--逆变器对比
    "//localhost:8614/": "wdw/energyOverview", // 储能监测--运行概况
    "//localhost:8615/": "wdw/deviceMonitor", // 储能监测--pcs/bms
    "//localhost:8616/": "wdw/quantityMonitor", // 储能监测--充放用电量
    
    "//localhost:8627/": "wdw/energyReport", // 储能监测--报表统计
    "//localhost:8628/": "wdw/energyDataAnalysis", // 储能监测--数据分析

    "//localhost:8617/": "wdw/chargingOverview", // 停充监测--运行概况
    "//localhost:8618/": "wdw/chargeData", // 停充监测--设备充电数据监控
    "//localhost:8619/": "wdw/chargeCapacity", // 停充监测--设备充电量监测
    "//localhost:8620/": "wdw/chargeStation", // 停充监测--充电站用电详情
    "//localhost:8621/": "wdw/strategyConfigure", // 策略配置--配置
    "//localhost:8622/": "wdw/strategyTable", // 策略配置--列表
    "//localhost:8623/": "wdw/strategyLog", // 策略配置--日志
    "//localhost:8624/": "wdw/manualControl", // 策略配置--手动控制
    "//localhost:8800/": "wdw/ChargeReport", // 停充监测--充电报表
    "//localhost:8801/": "wdw/ChargeAnalysis", // 停充监测--充电报表
    "//localhost:8900/": "vpp/overview", // 虚拟电厂--首页
    "//localhost:8901/": "vpp/plantmgr", // 虚拟电厂 --电厂管理
    "//localhost:8902/": "vpp/invitemgr", // 虚拟电厂 --邀约管理
    "//localhost:8903/": "vpp/replymgr", // 虚拟电厂 --调度管理
    "//localhost:8904/": "vpp/adjust", // 虚拟电厂 --可调节
    "//localhost:8905/": "vpp/userlog", // 虚拟电厂 --用户日志
    "//localhost:8906/": "vpp/monthbill", // 虚拟电厂 --月账单
    "//localhost:8907/": "vpp/clientbill", // 虚拟电厂 --客户对账单
    "//localhost:8908/": "vpp/clienteva", // 虚拟电厂 --客户调控能力
    "//localhost:8909/": "vpp/clientvppinvite", // 虚拟电厂 --客户企业邀约管理
    "//localhost:8910/": "vpp/clientvppbase", // 虚拟电厂 --客户端基线管理
    "//localhost:8923/": "vpp/screenVpp", // 虚拟电厂 --大屏
    "//localhost:9000/": "wdwScreen/#/home", // 微电网大屏
    "//localhost:9001/": "wdwScreen/#/widescreen", // 微电网大屏(宽屏)
    "//localhost:9002/": "wdwScreen/#/newScreen", // 微电网大屏(新版)
    "//localhost:8911/": "saas/listView", // saas --列表视图
    "//localhost:8912/": "saas/statisticsView", // saas --统计视图
    "//localhost:8913/": "saas/fullScreen", // saas --大屏展示
    "//localhost:8914/": "saas/photovoltaicMonitor", // saas --光伏监测
    "//localhost:8915/": "saas/stationReport", // saas --电站报表
    "//localhost:8916/": "saas/inverterReport", // saas --逆变器报表
    "//localhost:8917/": "saas/incomeReport", // saas --收益报表
    "//localhost:8918/": "saas/powerContrast", // saas --发电量对比
    "//localhost:8919/": "saas/comparative", // saas --同环比分析
    "//localhost:8920/": "saas/realStatus", // saas --运维实时状态
    "//localhost:8921/": "saas/alarm", // saas --运维告警
    "//localhost:8922/": "saas/income", // saas --收益计算
    "//localhost:8924/": "saas/pvScreen", // saas --大屏展示(新能源集团)

    "//localhost:9100/": "dnxspms/index", //充电桩--大屏
    "//localhost:9101/": "dnxspms/ums/card/list", //充电桩--卡片管理
    "//localhost:9102/": "dnxspms/menu/app/group/list", //充电桩--大客户管理
    "//localhost:9103/": "dnxspms/menu/app/car/list", //充电桩--车辆管理
    "//localhost:9104/": "dnxspms/ums/sys/member/auth/list", //充电桩--实名认证
    "//localhost:9105/": "dnxspms/park/parkParams", //充电桩--停车场管理
    "//localhost:9106/": "dnxspms/park/parkDiscountRecord", //充电桩--优惠券管理
    "//localhost:9107/": "dnxspms/mer/sharing", //充电桩--分账商户管理
    "//localhost:9108/": "dnxspms/withdraw/record", //充电桩--提现记录
    "//localhost:9109/": "dnxspms/shared/order", //充电桩--分账订单
    "//localhost:9110/": "dnxspms/tms/manufacture", //充电桩--电桩厂商
    "//localhost:9111/": "dnxspms/tms/pileCode", //充电桩--桩号管理
    "//localhost:9112/": "dnxspms/sys/display", //充电桩--灯箱管理
    "//localhost:9113/": "dnxspms/menu/charge/platform", //充电桩--平台管理
    "//localhost:9114/": "dnxspms/order/recharge", //充电桩--充值订单管理
    "//localhost:9115/": "dnxspms/sys/memberBilling", //充电桩--会员电价管理
    "//localhost:9116/": "dnxspms/ums/discover", //充电桩--资讯管理
    "//localhost:9117/": "dnxspms/order/member", //充电桩--会员订单管理
    "//localhost:9118/": "dnxspms/order/charge", //充电桩--充电订单管理
    "//localhost:9119/": "dnxspms/menu/charge/station/list", //充电桩--充电站管理
    "//localhost:9120/": "dnxspms/menu/charge/pile/list", //充电桩--充电桩管理
    "//localhost:9121/": "dnxspms/menu/charge/iot/list", //充电桩--4G卡管理
    "//localhost:9122/": "dnxspms/menu/charge/feature/list", //充电桩--电站分类管理
    "//localhost:9123/": "dnxspms/vip/manage/plus", //充电桩--零充会员管理
    "//localhost:9124/": "dnxspms/vip/manage/rule", //充电桩--临充会员规则
    "//localhost:9125/": "dnxspms/vms/sys/vip/rightIcon/list", //充电桩--会员权益图标
    "//localhost:9126/": "dnxspms/vms/sys/vip/right/list", //充电桩--会员权益
    "//localhost:9127/": "dnxspms/sys/coupon/list", //充电桩--卡券管理
    "//localhost:9128/": "dnxspms/sms/sys/couponActive", //充电桩--卡券活动管理
    "//localhost:9129/": "dnxspms/menu/swiper/list", //充电桩--轮播管理
    "//localhost:9130/": "dnxspms/ams/city/list", //充电桩--城市管理
    "//localhost:9131/": "dnxspms/tms/sys/station/impression", //充电桩--评价管理
    "//localhost:9132/": "dnxspms/service/manage/feedback", //充电桩--问题反馈管理
    "//localhost:9133/": "dnxspms/service/manage/problem", //充电桩--客服-问题库
    "//localhost:9134/": "dnxspms/service/manage/appeal", //充电桩--占位催挪管理
    "//localhost:9135/": "dnxspms/service/manage/parkAppeal", //充电桩--停车费申诉
    "//localhost:9136/": "dnxspms/service/manage/gunExplain", //充电桩--充电枪说明
    "//localhost:9137/": "dnxspms/menu/charge/operator/list", //充电桩--运营商管理
    "//localhost:9138/": "dnxspms/system/params", //充电桩--系统参数
    "//localhost:9139/": "dnxspms/menu/app/car/using/list", //充电桩--车辆用途管理
    "//localhost:9140/": "dnxspms/charge/impression", //充电桩--车友印象
    "//localhost:9141/": "dnxspms/vip/manage/power", //充电桩--权益参数
    "//localhost:9142/": "dnxspms/tms/sys/billing", //充电桩--电价策略管理
    "//localhost:9143/": "dnxspms/sys/charge/maintain", //充电桩--充电站维护计划
    "//localhost:9144/": "dnxspms/sys/attendance/record", //充电桩--考勤总览
    "//localhost:9145/": "dnxspms/sys/staff/people", //充电桩--运营人员管理
    "//localhost:9146/": "dnxspms/kms/patrol/plan", //充电桩--巡更计划
    "//localhost:9147/": "dnxspms/tool/excel/export/list", //充电桩--报表管理
    "//localhost:9148/": "dnxspms/tool/invoice/list", //充电桩--财务开票
    "//localhost:9149/": "saas/alarmInfo", //告警信息
    "//localhost:9150/": "saas/video", //视频监控

};

const devMap = {
    "//localhost:8600/": "//localhost:8600/saas",
    "//localhost:7000/": "//localhost:8602/wdw/eneoverview", // 能耗管理--概况
    "//localhost:7001/": "//localhost:8602/wdw/enebranch", // 能耗管理--支路能耗统计
    "//localhost:7002/": "//localhost:8602/wdw/enegroup", // 能耗管理--分组能耗统计
    "//localhost:7003/": "//localhost:8602/wdw/enebackstep", // 能耗管理--能耗折标分析
    "//localhost:7004/": "//localhost:8602/wdw/eneyoy", // 能耗管理--能耗同比
    "//localhost:7005/": "//localhost:8602/wdw/eneqoq", // 能耗管理--能耗环比
    "//localhost:7006/": "//localhost:8602/wdw/eneflow", // 能耗管理--能耗流向图
    "//localhost:7007/": "//localhost:8602/wdw/enesetting", // 能耗管理--分类分项配置

    "//localhost:7100/": "//localhost:8602/wdw/optoverview", // 运维管理--概况
    "//localhost:7101/": "//localhost:8602/wdw/opttour", // 运维管理--巡视工单
    "//localhost:7102/": "//localhost:8602/wdw/optrepair", // 运维管理--抢修工单
    "//localhost:7103/": "//localhost:8602/wdw/optdefect", // 运维管理--缺陷工单
    "//localhost:7104/": "//localhost:8602/wdw/optmaintenance", // 运维管理--维保工单
    "//localhost:7105/": "//localhost:8602/wdw/optproject", // 运维管理--项目配置
    "//localhost:7106/": "//localhost:8602/wdw/opttemplate", // 运维管理--模板配置
    "//localhost:7107/": "//localhost:8602/wdw/optplan", // 运维管理--巡视计划
    "//localhost:7108/": "//localhost:8602/wdw/optgroup", // 运维管理--运维班组

    "//localhost:8601/": "//localhost:8602/wdw/overView", // 驾驶舱--微网概况
    "//localhost:8603/": "//localhost:8602/wdw/screen", // 驾驶舱--光储充大屏
    "//localhost:8626/": "//localhost:8602/wdw/wideScreen", // 驾驶舱--光储充大屏20:9
    "//localhost:8625/": "//localhost:8602/wdw/simple", // 驾驶舱--简版大屏
    "//localhost:8604/": "//localhost:8602/wdw/electricityOverview",  // 配电监测--运行概况
    "//localhost:8605/": "//localhost:8602/wdw/powercspt", // 配电监测--用电量统计
    "//localhost:8606/": "//localhost:8602/wdw/powermgnt", // 配电监测--支路用电统计
    "//localhost:8607/": "//localhost:8602/wdw/transformer", // 配电监测--时段用电监测
    "//localhost:8608/": "//localhost:8602/wdw/trend", // 配电监测--时段用电对比
    "//localhost:8609/": "//localhost:8602/wdw/device", // 配电监测--设备用电对比
    "//localhost:8700/": "//localhost:8602/wdw/powerqult", // 配电监测--电能质量监测
    "//localhost:8701/": "//localhost:8602/wdw/enviroment", // 配电监测--环境监测
    "//localhost:8702/": "//localhost:8602/wdw/compyoy", // 配电监测--同环比分析
    "//localhost:8703/": "//localhost:8602/wdw/reportdoc", // 配电监测--用电分析报告
    "//localhost:8704/": "//localhost:8602/wdw/reportfgp", // 配电监测--尖峰平谷报表
    "//localhost:8705/": "//localhost:8602/wdw/reportmeter", // 配电监测--远程抄表
    "//localhost:8706/": "//localhost:8602/wdw/reportload", // 配电监测--负荷极值报表
    "//localhost:8707/": "//localhost:8602/wdw/feecos", // 配电监测--力调电费分析
    "//localhost:8708/": "//localhost:8602/wdw/traiff", // 配电监测--基本电费分析
    "//localhost:8709/": "//localhost:8602/wdw/transfanaly", // 配电监测--变压器经济运行

    "//localhost:8610/": "//localhost:8602/wdw/pvOverview", // 光伏监测--运行概况
    "//localhost:8611/": "//localhost:8602/wdw/powerMonitor", // 光伏监测--发用电量统计
    "//localhost:8612/": "//localhost:8602/wdw/inverterMonitor", // 光伏监测--逆变器监测
    "//localhost:8613/": "//localhost:8602/wdw/contrastAnalysis", // 光伏监测--逆变器对比
    "//localhost:8614/": "//localhost:8602/wdw/energyOverview", // 储能监测--运行概况
    "//localhost:8615/": "//localhost:8602/wdw/deviceMonitor", // 储能监测--pcs/bms
    "//localhost:8616/": "//localhost:8602/wdw/quantityMonitor", // 储能监测--充放用电量
    "//localhost:8617/": "//localhost:8602/wdw/chargingOverview", // 停充监测--运行概况
    "//localhost:8618/": "//localhost:8602/wdw/chargeData", // 停充监测--设备充电数据监控
    "//localhost:8619/": "//localhost:8602/wdw/chargeCapacity", // 停充监测--设备充电量监测
    "//localhost:8620/": "//localhost:8602/wdw/chargeStation", // 停充监测--充电站用电详情
    "//localhost:8621/": "//localhost:8602/wdw/strategyConfigure", // 策略配置--配置
    "//localhost:8622/": "//localhost:8602/wdw/strategyTable", // 策略配置--列表
    "//localhost:8623/": "//localhost:8602/wdw/strategyLog", // 策略配置--日志
    "//localhost:8624/": "//localhost:8602/wdw/manualControl", // 策略配置--手动控制
    "//localhost:8627/": "//localhost:8602/wdw/energyReport", // 储能监测--报表统计
    "//localhost:8628/": "//localhost:8602/wdw/energyDataAnalysis", // 储能监测--数据分析
    // "//localhost:8629/": "//localhost:8602/wdw/energyAlarm", // 储能监测--异常告警
    

    "//localhost:8800/": "//localhost:8602/wdw/ChargeReport", // 停充监测--充电报表
    "//localhost:8801/": "//localhost:8602/wdw/ChargeAnalysis", // 停充监测--充电报表

    "//localhost:8900/": "//localhost:8603/vpp/overview", // 虚拟电厂--首页
    "//localhost:8901/": "//localhost:8603/vpp/plantmgr", // 虚拟电厂 --电厂管理
    "//localhost:8902/": "//localhost:8603/vpp/invitemgr", // 虚拟电厂 --邀约管理
    "//localhost:8903/": "//localhost:8603/vpp/replymgr", // 虚拟电厂 --调度管理
    "//localhost:8904/": "//localhost:8603/vpp/adjust", // 虚拟电厂 --可调节
    "//localhost:8905/": "//localhost:8603/vpp/userlog", // 虚拟电厂 --用户日志
    "//localhost:8906/": "//localhost:8603/vpp/monthbill", // 虚拟电厂 --月账单
    "//localhost:8907/": "//localhost:8603/vpp/clientbill", // 虚拟电厂 --客户对账单
    "//localhost:8908/": "//localhost:8603/vpp/clienteva", // 虚拟电厂 --客户调控能力
    "//localhost:8909/": "//localhost:8603/vpp/clientvppinvite", // 虚拟电厂 --客户端企业邀约
    "//localhost:8910/": "//localhost:8603/vpp/clientvppbase", // 虚拟电厂 -- 客户端基线管理
    "//localhost:8923/": "//localhost:8603/vpp/screen", // 虚拟电厂 -- 大屏

    "//localhost:9000/": "//localhost:8601/wdwScreen/#/home", // 金瑞达 --首页
    "//localhost:9001/": "//localhost:8601/wdwScreen/#/widescreen", // 金瑞达 --首页(适配集控大厅的宽屏幕)
    "//localhost:9002/": "//localhost:8601/wdwScreen/#/newScreen", // 新版大屏

    "//localhost:8911/": "//localhost:8911/saas/listView", // saas --列表视图
    "//localhost:8912/": "//localhost:8912/saas/statisticsView", // saas --统计视图
    "//localhost:8913/": "//localhost:8913/saas/fullScreen", // saas --大屏展示
    "//localhost:8914/": "//localhost:8914/saas/photovoltaicMonitor", // saas --光伏监测
    "//localhost:8915/": "//localhost:8915/saas/stationReport", // saas --电站报表
    "//localhost:8916/": "//localhost:8916/saas/inverterReport", // saas --逆变器报表
    "//localhost:8917/": "//localhost:8917/saas/incomeReport", // saas --收益报表
    "//localhost:8918/": "//localhost:8918/saas/powerContrast", // saas --发电量对比
    "//localhost:8919/": "//localhost:8919/saas/comparative", // saas --同环比分析
    "//localhost:8920/": "//localhost:8920/saas/realStatus", // saas --运维实时状态
    "//localhost:8921/": "//localhost:8921/saas/alarm", // saas --运维告警
    "//localhost:8922/": "//localhost:8922/saas/income", // saas --收益计算
    "//localhost:8924/": "//localhost:8913/saas/pvScreen", // saas --大屏展示(新能源集团)

    "//localhost:9100/": "//localhost:80/dnxspms/index", //充电桩--大屏
    "//localhost:9101/": "//localhost:80/dnxspms/ums/card/list", //充电桩--卡片管理
    "//localhost:9102/": "//localhost:80/dnxspms/menu/app/group/list", //充电桩--大客户管理
    "//localhost:9103/": "//localhost:80/dnxspms/menu/app/car/list", //充电桩--车辆管理
    "//localhost:9104/": "//localhost:80/dnxspms/ums/sys/member/auth/list", //充电桩--实名认证
    "//localhost:9105/": "//localhost:80/dnxspms/park/parkParams", //充电桩--停车场管理
    "//localhost:9106/": "//localhost:80/dnxspms/park/parkDiscountRecord", //充电桩--优惠券管理
    "//localhost:9107/": "//localhost:80/dnxspms/mer/sharing", //充电桩--分账商户管理
    "//localhost:9108/": "//localhost:80/dnxspms/withdraw/record", //充电桩--提现记录
    "//localhost:9109/": "//localhost:80/dnxspms/shared/order", //充电桩--分账订单
    "//localhost:9110/": "//localhost:80/dnxspms/tms/manufacture", //充电桩--电桩厂商
    "//localhost:9111/": "//localhost:80/dnxspms/tms/pileCode", //充电桩--桩号管理
    "//localhost:9112/": "//localhost:80/dnxspms/sys/display", //充电桩--灯箱管理
    "//localhost:9113/": "//localhost:80/dnxspms/menu/charge/platform", //充电桩--平台管理
    "//localhost:9114/": "//localhost:80/dnxspms/order/recharge", //充电桩--充值订单管理
    "//localhost:9115/": "//localhost:80/dnxspms/sys/memberBilling", //充电桩--会员电价管理
    "//localhost:9116/": "//localhost:80/dnxspms/ums/discover", //充电桩--资讯管理
    "//localhost:9117/": "//localhost:80/dnxspms/order/member", //充电桩--会员订单管理
    "//localhost:9118/": "//localhost:80/dnxspms/order/charge", //充电桩--充电订单管理
    "//localhost:9119/": "//localhost:80/dnxspms/menu/charge/station/list", //充电桩--充电站管理
    "//localhost:9120/": "//localhost:80/dnxspms/menu/charge/pile/list", //充电桩--充电桩管理
    "//localhost:9121/": "//localhost:80/dnxspms/menu/charge/iot/list", //充电桩--4G卡管理
    "//localhost:9122/": "//localhost:80/dnxspms/menu/charge/feature/list", //充电桩--电站分类管理
    "//localhost:9123/": "//localhost:80/dnxspms/vip/manage/plus", //充电桩--零充会员管理
    "//localhost:9124/": "//localhost:80/dnxspms/vip/manage/rule", //充电桩--临充会员规则
    "//localhost:9125/": "//localhost:80/dnxspms/vms/sys/vip/rightIcon/list", //充电桩--会员权益图标
    "//localhost:9126/": "//localhost:80/dnxspms/vms/sys/vip/right/list", //充电桩--会员权益
    "//localhost:9127/": "//localhost:80/dnxspms/sys/coupon/list", //充电桩--卡券管理
    "//localhost:9128/": "//localhost:80/dnxspms/sms/sys/couponActive", //充电桩--卡券活动管理
    "//localhost:9129/": "//localhost:80/dnxspms/menu/swiper/list", //充电桩--轮播管理
    "//localhost:9130/": "//localhost:80/dnxspms/ams/city/list", //充电桩--城市管理
    "//localhost:9131/": "//localhost:80/dnxspms/tms/sys/station/impression", //充电桩--评价管理
    "//localhost:9132/": "//localhost:80/dnxspms/service/manage/feedback", //充电桩--问题反馈管理
    "//localhost:9133/": "//localhost:80/dnxspms/service/manage/problem", //充电桩--客服-问题库
    "//localhost:9134/": "//localhost:80/dnxspms/service/manage/appeal", //充电桩--占位催挪管理
    "//localhost:9135/": "//localhost:80/dnxspms/service/manage/parkAppeal", //充电桩--停车费申诉
    "//localhost:9136/": "//localhost:80/dnxspms/service/manage/gunExplain", //充电桩--充电枪说明
    "//localhost:9137/": "//localhost:80/dnxspms/menu/charge/operator/list", //充电桩--运营商管理
    "//localhost:9138/": "//localhost:80/dnxspms/system/params", //充电桩--系统参数
    "//localhost:9139/": "//localhost:80/dnxspms/menu/app/car/using/list", //充电桩--车辆用途管理
    "//localhost:9140/": "//localhost:80/dnxspms/charge/impression", //充电桩--车友印象
    "//localhost:9141/": "//localhost:80/dnxspms/vip/manage/power", //充电桩--权益参数
    "//localhost:9142/": "//localhost:80/dnxspms/tms/sys/billing", //充电桩--电价策略管理
    "//localhost:9143/": "//localhost:80/dnxspms/sys/charge/maintain", //充电桩--充电站维护计划
    "//localhost:9144/": "//localhost:80/dnxspms/sys/attendance/record", //充电桩--考勤总览
    "//localhost:9145/": "//localhost:80/dnxspms/sys/staff/people", //充电桩--运营人员管理
    "//localhost:9146/": "//localhost:80/dnxspms/kms/patrol/plan", //充电桩--巡更计划
    "//localhost:9147/": "//localhost:80/dnxspms/tool/excel/export/list", //充电桩--报表管理
    "//localhost:9148/": "//localhost:80/dnxspms/tool/invoice/list", //充电桩--财务开票

    "//localhost:9150/": "//localhost:8600/saas/video", //视频监控
}

export default function hostMap(host) {
    if (process.env.NODE_ENV === "development") return devMap[host];
    if (process.env.NODE_ENV === "production") {
        return `${window.location.protocol}//${window.location.host}/${map[host]}`;
    }
    return host;
}
