import React from "react";
import alarm_icon from './assets/alarm_icon.png'
import styles from "./index.scss";

const Tips = () => {
  return (
    <div style={{
      width: '46px', height: '46px', zIndex: '1000 !important', transformOrigin: 'right bottom 0',
      cursor: 'pointer', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center'
    }}>
      <img src={alarm_icon} alt="" />
    </div>
  );
};

export default Tips;
