import React from "react";

const switchTheme = (theme) => {
  // console.log(theme, 'tehh')
  document.documentElement.setAttribute('data-theme', theme || 'dark');
  // if (theme === 'dark') {
  //   document.documentElement.setAttribute('data-theme', 'dark');
  // } else {
  //   document.documentElement.setAttribute('light');
  //   // document.documentElement.classList.remove('dark');
  // }
}

export default switchTheme;