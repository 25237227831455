//redux
import { createStore } from "redux";
const defaultValue = {
  userInfo: {},
  isLogin: false,
  initBellAlarm: [],
  takeBellAlarm: [],
  headAlarmNum: 0,
  showAlarmNotice: true,
};
const reducers = (state = defaultValue, action) => {
  switch (action.type) {
    case "setUserInfo":
      return { ...state, ...{ userInfo: action.userInfo } };
    case "setLogin":
      return { ...state, ...{ isLogin: action.isLogin } };
    case "setInitBellAlarm":
      return {
        ...state,
        initBellAlarm: () => {
          state.initBellAlarm = action.initBellAlarm;
          state.headAlarmNum = 0;
          action.initBellAlarm.forEach((entry) => {
            if (entry.m_type !== 18) {
              state.headAlarmNum += 1;
            }
          });
        } };
    case "insertBellAlarm":
      return {
        ...state,
        initBellAlarm: () => {
          state.initBellAlarm.unshift(action.entry);
        },
        takeBellAlarm: () => {
          state.takeBellAlarm.push(action.entry);
        },
        headAlarmNum: () => {
          if (action.entry.m_type !== 18) {
            state.headAlarmNum += 1;
          }
        },
      };
    case "updateBellAlarm":
      return {
        ...state,
        initBellAlarm: () => {
          state.initBellAlarm.forEach((entry, index) => {
            if (entry.m_id === action.item.m_id) {
              state.initBellAlarm[index] = action.item;
            }
          });
        },
        takeBellAlarm: () => {
          state.initBellAlarm.forEach((entry, index) => {
            if (entry.m_id === action.item.m_id) {
              state.takeBellAlarm.push(action.item);
            }
          });
        },
      };
    case "DELETE_BELLALARM":
      return {
        ...state,
        initBellAlarm: () => {
          state.initBellAlarm.forEach((entry, index) => {
            if (entry.m_id === action.item.m_id) {
              state.initBellAlarm.splice(index, 1);
            }
          });
        },
        takeBellAlarm: () => {
          state.initBellAlarm.forEach((entry, index) => {
            if (entry.m_id === action.item.m_id) {
              state.takeBellAlarm.push(action.item);
            }
          });
        },
        headAlarmNum: () => {
          state.initBellAlarm.forEach((entry, index) => {
            if (entry.m_id === action.item.m_id) {
              state.headAlarmNum -= 1;
            }
          });
        },
      };
    case "clearTakeBellAlarm":
      return {
        ...state,
        takeBellAlarm: () => {
          state.takeBellAlarm = [];
        },
      };
    //setShowAlarmNotice
    case "setShowAlarmNotice":
      return {
        ...state,
        showAlarmNotice: () => {
          state.showAlarmNotice = action.showAlarmNotice;
        },
      };
    default:
      return { ...state, ...{ userInfo: {}, isLogin: false } };
  }
};

export default createStore(reducers);
