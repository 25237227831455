import React, { useEffect, useState } from "react";
import { Button } from "antd";
import styles from "./index.scss";
import dayjs from "dayjs";
const Notice = (props) => {
  const [showIndex, setShowIndex] = useState(0);
  const [showItem, setShowItem] = useState();

  const nextShow = () => {
    let index = showIndex + 1;
    setShowIndex(index);
  };

  const ignoredShow = () => {
    props.Ignore();
  };

  useEffect(() => {
    setShowItem(props?.data?.[showIndex]);
  }, [showIndex]);

  const colorData = {
    ignorable: '#1A7477',
    notice: '#DCA700',
    generally: '#FF8643',
    serious: '#FF4343',
    fault: '#8B4300',
    disaster: '#B50000',
  }

  const levelData = {
    ignorable: '可忽略',
    notice: '注意',
    generally: '一般',
    serious: '严重',
    fault: '故障',
    disaster: '灾难',
  }

  return (
    <div className={styles.notice}>
      <p className={styles["notice-content"]}>
        {showItem && (
          <>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '8px 0' }}>
              <div className={styles.level} style={{
                border: `1px solid ${colorData[showItem.event_level]}`,
                color: colorData[showItem.event_level], padding: '0 4px', borderRadius: 4, width: 60, textAlign: 'center'
              }}>{levelData[showItem.event_level]}</div>
              <div style={{color: '#666'}}>{dayjs(showItem.start_ts).format('YYYY-MM-DD HH:mm:ss')}</div>
            </div>
            <div>{showItem.stationName || '-'}的{showItem.deviceName || '-'}发生【{showItem.event_label}】</div>
          </>
        )}
      </p>
      <div className={styles["notice-bottom"]}>
        <Button className={styles.ignore} style={{ marginRight: 10 }} type="default" size="small" onClick={ignoredShow}>忽略</Button>
        <Button
          type="info"
          size="small"
          disabled={showIndex === props.data.length - 1}
          onClick={nextShow}
        >下一条
        </Button>
      </div>
    </div>
  );
};

export default Notice;
