import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUser } from '../reducers';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import ajax from '../api';

const LoginPage = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get('token');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (<div></div>)
};

export default LoginPage;