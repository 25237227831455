// 跳转到登录
export function redirectToLogin() {
    // swdn.eepx.com.cn   10.4.254.188
    // window.location.href = 'http://microvpp.swdnkj.com/sso/login?redirect=http://localhost:7700/';
    window.location.href = `${window.location.protocol}//${window.location.host}/sso/login?redirect=${window.location.protocol}//${window.location.host}`;
}

// 跳转到退出
export function redirectToLogout() {
    // window.location.href = 'http://microvpp.swdnkj.com/sso/logout?redirect=http://localhost:7700/'; //192.168.18.75   localhost
    window.location.href = `${window.location.protocol}//${window.location.host}/sso/logout?redirect=${window.location.protocol}//${window.location.host}`;
}

//
export function loginInfo(value) {
    if (value) {
        localStorage.removeItem('saas-user');
        localStorage.removeItem('tenantId');
        localStorage.removeItem('saas-token');
        redirectToLogout();
    }
}

export function gotoClient() {
    console.log('test view')
}
