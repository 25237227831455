import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  tenantId: '',
  staId: '',
  stationList: [],
  vppList: [],
  vppId: '',
  isClient: false,
  currentTenant: {},
  theme: 'dark'
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
    },
    setTenantId: (state, action) => {
      state.tenantId = action.payload
    },
    setStaId: (state, action) => {
      state.staId = action.payload
    },
    setStationList: (state, action) => {
      state.stationList = action.payload
    },
    setVppList: (state, action) => {
      state.vppList = action.payload
    },
    setIsClient: (state, action) => {
      state.isClient = action.payload
    },
    setTheme: (state, action) => {
      state.theme = action.payload;
    }
  }
})

export const { setUser, setTenantId, setStaId, setStationList, setVppList, setIsClient, setTheme } = userSlice.actions;
export default userSlice.reducer;