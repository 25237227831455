import React, { useEffect, useState } from "react";
import { Modal, Button, Tag, Radio } from "antd";

const ThemeModal = (props) => {
  const { visible } = props;
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'dark');

  const handleOk = () => {
    localStorage.setItem('theme', theme);
    props && props.onClose(theme);
  }

  const handleCancel = () => {
    props && props.onClose();
  }

  const handleChange = (e) => {
    setTheme(e.target.value);
  }

  return (
    <Modal title="主题设置" open={visible} maskClosable={false} onOk={handleOk} onCancel={handleCancel} okText="确定" cancelText="取消">
      <Radio.Group onChange={handleChange} defaultValue={theme}>
        <Radio.Button value="dark">
          <span style={{
            display: 'inline-block',
            verticalAlign: 'middle',
            marginRight: 6,
            width: 14,
            height: 14,
            borderRadius: 2,
            backgroundColor: '#000',
            border: '1px solid #ececec'
          }}></span>
          深色
        </Radio.Button>
        <Radio.Button value="light">
          <span style={{
            display: 'inline-block',
            verticalAlign: 'middle',
            marginRight: 6,
            width: 14,
            height: 14,
            borderRadius: 2,
            backgroundColor: '#2471E5',
            border: '1px solid #ececec'
          }}></span>
          蓝色
        </Radio.Button>
      </Radio.Group>
    </Modal>
  )
}

export default ThemeModal;